$primary: 'blue';
@import "ember-bootstrap/bootstrap";
@import 'ember-paper';

body {
  margin:           0 !important;
  font-family:      'Roboto', 'Noto', sans-serif !important;
  background-color: rgba(216, 205, 205, 0.41) !important;
  height:           100% !important;
}

@media only screen and (min-width: 768px) {
  .md-card.md-default-theme, md-card{
    max-width: 768px !important;
    margin: 8px auto;
  }
}

a, a:hover {
  text-decoration: none;
  color: black;
}

.liquid-child {
  min-height: 100vh;
}

.toolbar {
  background-color: #002F6C !important;
  height: 9vh;

  p {
    margin: auto;
  }
}

table {
  margin-bottom: 8px;
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-radius: 2px;
  overflow: hidden;
}

td, th {
  padding: 12px 16px;
  text-align: left;
}

td {
  vertical-align: top;
}

td.description *:first-child {
  margin-top: 0;
}

td.description *:last-child {
  margin-bottom: 0;
}

tr:nth-child(odd) td {
  background-color: lighten(color($primary, '50'), 2);
}

tr:nth-child(even) td {
  background-color: lighten(color($primary, '50'), 4);
}

th {
  background-color: #002F6C;
  color: white;
}

tr.subheading td {
  background-color: color($primary, '100');
}

.md-button.md-default-theme:not([disabled]):hover, .md-button:not([disabled]):hover {
  background-color: transparent !important;
}

.md-button.md-default-theme:not([disabled]).md-focused, .md-button:not([disabled]).md-focused {
  background-color: transparent !important;
}

.Helsinki {
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    height: 33vh !important;
  }
  width: 33%;
  height:100vh;
  background-color: #002F6C;
  color: white;
  text-align: center;
  float:left;

  p {
    @media only screen and (max-width: 600px) {
      line-height: 33vh !important;
    }
    font-size: 30px;
    font-family: sans-serif;
    margin: 0;
    line-height: 100vh;
  }

  &:hover {
    background-color: rgba(6, 14, 116, 0.82);
  }
}

h4 {
  margin-left: 18px;
}

.programma {
  p {
     margin: 0px;
    font-size: 10px;
    font-weight: bolder;
    font-style: italic;
  }

  td:first-child {
    width: 135px;
  }
}

.contact {
  td:first-child {
    width:350px;
  }

  td {
    vertical-align: middle;
  }
}

.Talin {
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    height: 34vh !important;
  }
  width: 34%;
  height:100vh;
  background-color: #FFFFFF;
  text-align: center;
  float:left;

  p {
    @media only screen and (max-width: 600px) {
      line-height: 34vh !important;
    }
    font-size: 30px;
    font-family: sans-serif;
    margin: 0;
    line-height: 100vh;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.Riga {
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    height: 33vh !important;
  }
  width: 33%;
  height:100vh;
  background-color: #002F6C;
  text-align: center;
  color: white;
  float:left;

  p {
    @media only screen and (max-width: 600px) {
      line-height: 33vh !important;
    }
    font-size: 30px;
    font-family: sans-serif;
    margin: 0;
    line-height: 100vh;
  }

  &:hover {
    background-color: rgba(6, 14, 116, 0.82);
  }
}

.valuta {
  width: 50%;
  margin-top: 0px;
  margin-left: 15px;
  margin-right: auto;

  &-output {
    margin-top: -30px;
    margin-left: 19px;
    margin-right: auto;
  }
}

md-list-item:hover, md-list-item:hover .md-list-item-inner:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.site-sidenav,
.site-sidenav.md-locked-open-add-active,
.site-sidenav.md-locked-open {
  width:     272px;
  min-width: 272px;
  max-width: 272px;
}
.site-sidenav > * {
  min-width: 218px;
}
